import React, { useState, useEffect } from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import '../estilos/General.css';
import Teorico from '../componentes/Teorico';
import Teorico2 from '../componentes/Teorico2';
import Practico from '../componentes/Practico';
import Slider from '../componentes/Reseñas';
import CardResenas from '../componentes/CardResenas';
import TituloEncabezado from '../componentes/TituloEncabezado';
import VuelveConducir from '../componentes/VuelveConducir';
import CardTeorico from '../componentes/CardTeorico';
import CardPractico from '../componentes/CardPractico';
import CardVuelveConducir from '../componentes/CardVuelveConducir';
import Loader from '../componentes/Loader';
import Navidad from '../componentes/Navidad';
import NavbarClasesOnline from '../componentes/NavbarClasesOnline/NavbarClasesOnline';

const Inicio = () => {

  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  return (
    <div className="wrapper">
      {/* {loading ? (
        <Loader /> 
        ) : (
        <> */}
          <NavbarClasesOnline />
          {/* <Header /> */}
          {/* <Navidad /> */}
          <TituloEncabezado texto="TEÓRICO" />
          {/* <Teorico2/> */}
          <CardTeorico />
          <TituloEncabezado texto="PRÁCTICO" />
          {/* <Practico/> */}
          <CardPractico />
          <TituloEncabezado texto="VUELVE A CONDUCIR" />
          {/* <VuelveConducir/> */}
          <CardVuelveConducir />
          <TituloEncabezado texto="RESEÑAS" />
          {/* //<Slider /> */}
          <CardResenas />
          <Footer />
        {/* </>
      )} */}
    </div>
  );
}

export default Inicio;
