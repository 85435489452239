import React, { useEffect, useState } from 'react';
import './NavbarClasesOnline.css';

function NavbarClasesOnline() {
    const [isSticky, setIsSticky] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
            <a href='/'>
                <img src={require("../../imagenes/Logo.png")} className="logo"></img>
            </a>
            <div className="logo"></div>
            <ul className="nav-links">
                <input type="checkbox" id="checkbox_toggle" checked={isOpen} onChange={toggleMenu} />
                <label htmlFor="checkbox_toggle" className="hamburger">{isOpen ? '×' : '☰'}</label>
                <div className={`menu ${isOpen ? 'open' : ''}`}>
                    <li className='item-li'><a href="/">Inicio</a></li>
                    <li className='item-li'><a href="/contacto">Contacto</a></li>
                    <li className='item-li'><a href="/donde-estamos">¿Dónde estamos?</a></li>
                    <li className='item-li'><a href="https://alumno.examentrafico.com/#/login/0">Realizar Test</a></li>
                    <li className='item-li'><a href="https://sedeapl.dgt.gob.es:7443/WEB_NOTP_CONSULTA/consultaNota.faces">Resultado Examen</a></li>
                    <li className='item-li iconos-flex'>
                        <svg className='icono-clasesonline' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                        </svg>
                        <a href='https://autoescuelacobisa.vercel.app' className='co'>Clases Online</a>
                    </li>
                </div>
            </ul>
         </nav>
    );
}

export default NavbarClasesOnline;