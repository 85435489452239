import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "../estilos/CardResenas.css";

const CardResenas = () => {
  const [opiniones, setOpiniones] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opinionesCargadas, setOpinionesCargadas] = useState(false);

  const nextOpinionRef = useRef(); // Referencia para la función nextOpinion
  const timerRef = useRef(); // Referencia para el intervalo del timer

  // Obtener las opiniones de la API al cargar el componente
  useEffect(() => {
    const fetchOpiniones = async () => {
      try {
        const response = await axios.get('https://api-opiniones-autoescuela-vercel.vercel.app/resenas');
        setOpiniones(response.data);
        setOpinionesCargadas(true);
        // Generar un índice aleatorio dentro del rango del arreglo de opiniones
        const randomIndex = Math.floor(Math.random() * response.data.length);
        setCurrentIndex(randomIndex);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchOpiniones();
  }, []);

  // Función para avanzar a la siguiente opinión
  const nextOpinion = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % opiniones.length);
  };

  // Función para retroceder a la opinión anterior
  const prevOpinion = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + opiniones.length) % opiniones.length);
  };

  // Navegación automática cada 10 segundos
  useEffect(() => {
    if (opinionesCargadas) {
      nextOpinionRef.current = nextOpinion; // Actualizar la referencia de nextOpinion

      const timer = setInterval(() => {
        nextOpinionRef.current();
      }, 17000);

      timerRef.current = timer; // Guardar la referencia del intervalo
      return () => clearInterval(timer);
    }
  }, [opinionesCargadas]);

  // Reiniciar el intervalo
  const resetTimer = () => {
    clearInterval(timerRef.current);
    const timer = setInterval(() => {
      nextOpinionRef.current();
    }, 17000);
    timerRef.current = timer;
  };

  // Avanzar a la siguiente opinión y reiniciar el intervalo
  const handleNextClick = () => {
    nextOpinion();
    resetTimer();
  };

  // Retroceder a la opinión anterior y reiniciar el intervalo
  const handlePrevClick = () => {
    prevOpinion();
    resetTimer();
  };

  return (
    <div className="card-resenas">
      <div className="nav-buttons-resenas">
        <button className='next-button-resenas' onClick={handlePrevClick}><i class="fa-solid fa-left-long"></i></button>
        <button className='prev-button-resenas' onClick={handleNextClick}><i class="fa-solid fa-right-long"></i></button>
      </div>
      <div className="opinion-card-resenas">
        <h3>{opiniones[currentIndex]?.nombre}</h3>
        <div className="stars-resenas">
          {Array.from({ length: opiniones[currentIndex]?.numeroestrellas }).map((_, index) => (
            <span key={index}>★</span>
          ))}
        </div>
        <p className='caracteristicas-resenas'>{opiniones[currentIndex]?.caracteristicas}</p>
        <p>{opiniones[currentIndex]?.opinion}</p>
      </div>
    </div>
  );
};

export default CardResenas;
