import React from "react";
import "../estilos/Practico.css";

function Practico() {
    const cardContent1 = (
        <div className="main-container">
            <div className="top-circle"></div>
            <img src={require("../imagenes/cochemanual.png")} alt="Coche" className="user" />
            <h2 className="tituloManual"> Clases Prácticas Manual </h2>
            {/* <h4 className="job-title"></h4> */}
            {/* <ul className="social">
                <li className="list-item">
                    <span className="num">856</span>
                    <br />
                    <span>Posts</span>
                </li>
                <li className="list-item">
                    <span className="num">2012</span>
                    <br />
                    <span>Likes</span>
                </li>
                <li className="list-item">
                    <span className="num">28010</span>
                    <br />
                    <span>Followers</span>
                </li>
            </ul> */}

            <p className="description">
                Aprende las técnicas de conducción más eficientes durante los 45 minutos de nuestras clases prácticas de conducir por Toledo.
            </p>
            <ul className="ventajas">
                <li>caracteristica1</li>
                <li>caracteristica2</li>

                {/* <li>-</li>
                <li>-</li>
                <li>-</li> */}
            </ul>
            <div className="buttons">
                {/* <button className="active btn"> Follow </button> */}
                <button className="btn-info"> Más Información </button>
            </div>
        </div>

    );

    const cardContent2 = (
        <div className="main-container">
            <div className="circuloAutomatico"></div>
            <img src={require("../imagenes/cocheautomatico.png")} alt="Coche" className="cocheautomatico" />
            <h2 className="tituloAutomatico"> Clases Prácticas Automático</h2>
            {/* <h4 className="job-title"></h4> */}
            {/* <ul className="social">
                <li className="list-item">
                    <span className="num">856</span>
                    <br />
                    <span>Posts</span>
                </li>
                <li className="list-item">
                    <span className="num">2012</span>
                    <br />
                    <span>Likes</span>
                </li>
                <li className="list-item">
                    <span className="num">28010</span>
                    <br />
                    <span>Followers</span>
                </li>
            </ul> */}

            <p className="description">
           Aprende a conducir de forma más fácil, olvídate del pedal de embrague y la palanca de cambios, con el cambio automático todo es más sencillo.
            </p>
            <ul className="ventajas">
                <li>caracteristica1</li>
                <li>caracteristica2</li>
{/*                 
                <li>-</li>
                <li>-</li>
                <li>-</li>
                <li>-</li> */}
            </ul>
            <div className="buttons">
                {/* <button className="active btn"> Follow </button> */}
                <button className="btn-info"> Más información </button>
            </div>
        </div>

    );

    return (
        <div className="container-grander">
            <div className="card-container">
                <div className="main-container">{cardContent1}</div>
                <div className="main-container">{cardContent2}</div>
            </div>
        </div>
    );
}

export default Practico;