import React from 'react';
import Footer from '../componentes/Footer';
import '../estilos/ContactoNueva.css'
import '../estilos/General.css'
import NavbarClasesOnline from '../componentes/NavbarClasesOnline/NavbarClasesOnline';
import FormularioContacto from '../componentes/FormularioContacto/FormularioContacto';
import CardsContacto from '../componentes/CardsContacto/CardsContacto';

const Contacto = () => {
    return (
      <div>
        <NavbarClasesOnline />
            <div className='contacto-nueva'>
                <CardsContacto />
                <FormularioContacto />
            </div>
        <Footer />
      </div>
    );
  }
  
  export default Contacto;