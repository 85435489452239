import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inicio from './pages/Inicio';
import ContactoNueva from './pages/ContactoNueva';
import DondeEstamos from './pages/DondeEstamos';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad';
import UnirseClasesOnline from './pages/UnirseClasesOnline';
// import Inicio from './pages/Inicio';
// import Contacto from './pages/Contacto';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Inicio />} />
        <Route exact path="/contacto" element={<ContactoNueva />} />
        <Route exact path="/donde-estamos" element={<DondeEstamos />} />
        <Route exact path="/localización" element={<DondeEstamos />} />
        <Route exact path="/política-de-privacidad" element={<PoliticaPrivacidad />} />
        <Route exact path="/como-unirse-a-las-clases-online" element={<UnirseClasesOnline />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
