import React from 'react';
import './CardContactoIndividual.css';

function CardContactoIndividual(props) {
    const { icon, text, descripcion, descripcion2 } = props;
  
    return (
      <div className="card-contacto">
        <div className="image-card-contacto">{icon}</div>
        <p className="h1-card-contacto">{text}</p>
        <p className="number-h1-card-contacto">{descripcion}</p>
        {descripcion2 && <p className="number-h1-card-contacto">{descripcion2}</p>}
      </div>
    );
}

export default CardContactoIndividual;
