import React from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import '../estilos/General.css'
import Localizacion from '../componentes/Localizacion';
import NavbarClasesOnline from '../componentes/NavbarClasesOnline/NavbarClasesOnline';

const DondeEstamos = () => {
  return (
    <div className="wrapper">
      {/* <Header /> */}
      <NavbarClasesOnline />
      <Localizacion />
      <Footer />
    </div>
  );
}

export default DondeEstamos;