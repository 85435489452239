
import React, { useState, useRef } from 'react';
import './FormularioContacto.css';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const FormularioContacto = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const formRef = useRef();

  const handleFocus = (e) => {
    const parent = e.target.parentNode;
    parent.classList.add('focus');
  };

  const handleBlur = (e) => {
    const parent = e.target.parentNode;
    if (e.target.value === '') {
      parent.classList.remove('focus');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const serviceID = 'default_service';
    const templateID = 'template_j2ts2va';
    const userID = 'CaJRbgvJ8Y-jHJ4ZU';

    emailjs.sendForm(serviceID, templateID, formRef.current, userID)
      .then(() => {
        Swal.fire({
          title: 'Enviado',
          text: 'El mensaje ha sido enviado de forma correcta. Trataremos de responder por WhatsApp o por teléfono lo antes posible.',
          icon: 'success',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#FFAF13',
          customClass: {
            confirmButton: 'swal-button--confirm',
          },
          allowOutsideClick: false,
        });
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  };

  return (
    <div className="container-form-contacto">
      <span className="big-circle"></span>
      <img src="/public/logo512.png" className="square" alt="" />
      <div className="form-contacto">
        <div className="informacion-contacto">
          <h3 className="titulo-formulario-contacto">¡Contacta con nosotros!</h3>
          <p className="texto-formulario-contacto">
            Puedes confiar en nosotros para resolver todas tus dudas y consultas. Estamos aquí para ayudarte en cualquier aspecto que necesites. Nuestro equipo de expertos está comprometido a brindarte el mejor servicio y una gran atención personalizada.
          </p>

          <div className="container-redes-sociales">
            {/* <p>También nos puedes hablar por:</p> */}
            <div className="iconos-redes-sociales">
              <a href="https://api.whatsapp.com/send?phone=617658338&text=Hola,%20vengo%20de%20la%20p%C3%A1gina%20web">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="mailto:autoescuelacobisa@hotmail.es">
                <i className="fas fa-envelope"></i>
              </a>
              <a href="https://www.instagram.com/autoescuelacobisa">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://m.facebook.com/Autoescuela-Cobisa-591947084252463/?locale=es_ES">
                <i className="fab fa-facebook-f"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="formulario-contacto">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form ref={formRef} className='formulario-form' onSubmit={handleSubmit} autoComplete="off">
            <div className="input-container-formulario-contacto">
              <input
                type="text"
                name="name"
                className="input-formulario-contacto"
                value={formData.name}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              <label>Nombre</label>
              <span>Nombre</span>
            </div>
            <div className="input-container-formulario-contacto">
              <input
                type="tel"
                name="phone"
                className="input-formulario-contacto"
                value={formData.phone}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
              <label>Teléfono</label>
              <span>Teléfono</span>
            </div>
            <div className="input-container-formulario-contacto textarea">
              <textarea
                name="message"
                className="input-formulario-contacto"
                value={formData.message}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              ></textarea>
              <label>Mensaje</label>
              <span>Mensaje</span>
            </div>
            <input type="submit" value="Enviar" className="btn-formulario-contacto-enviar" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormularioContacto;