import React from 'react';
import './UnirseClasesOnline.css';
import Movil from "../../imagenes/UnirseClasesOnlineMovil.png"
import Ordenador from "../../imagenes/UnirseClasesOnlineOrdenador.png"
import PDFMovil from "../../documents/AutoescuelaCobisaClasesOnlineMovil.pdf"
import PDFOrdenador from "../../documents/AutoescuelaCobisaClasesOnlineOrdenador.pdf"

const UnirseClasesOnline = () => {

  const pdfClasesOnlineMovil = () => {
    // Realiza la acción deseada cuando se hace clic en el card 1
    window.open(PDFMovil, '_blank');
    // Por ejemplo, puedes redirigir a otra página usando window.location.href
    // window.location.href = "/ruta-a-otra-pagina";
  };

  const pdfClasesOnlineOrdenador = () => {
    // Realiza la acción deseada cuando se hace clic en el card 2
    window.open(PDFOrdenador, '_blank');
    // Por ejemplo, puedes mostrar más información usando un modal o una alerta
    // alert("Hiciste clic en el card 2");
  };

  return (
    <>
    <div className='encabezado-clases-online'>
            ¿Desde que dispositivo te vas a unir a las Clases Online?
    </div>
    
      {/* Card 1 */}
    <div className="cards-container-unirse-clases-online">
      <div className="card-unirse-clases-online" onClick={pdfClasesOnlineMovil}>
        <img
          className="card-img-unirse-clases-online"
          src={Movil}
          alt="Imagen Página Web Autoescuela Cobisa desde Móvil y Tablet"
        />
        <div className="card-content-unirse-clases-online">
          <h2 className="card-title-unirse-clases-online">DESDE MÓVIL O TABLET</h2>
        </div>
      </div>

      {/* Card 2 */}
      <div className="card-unirse-clases-online" onClick={pdfClasesOnlineOrdenador}>
        <img
          className="card-img-unirse-clases-online"
          src={Ordenador}
          alt="Imagen Página Web Autoescuela Cobisa desde Ordenador"
        />
        <div className="card-content-unirse-clases-online">
          <h2 className="card-title-unirse-clases-online">DESDE ORDENADOR</h2>
        </div>
      </div>
    </div>
    </>
  );
};

export default UnirseClasesOnline;
