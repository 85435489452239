import React from 'react';
import '../estilos/CardTeorico.css';
import ClaseOnline from "../imagenes/claseonline.png";
import Profesor from "../imagenes/profesor.jpg";
import Test from "../imagenes/test.png";

const CardTeorico = () => {
  return (
    <div className="cards-container-teorico">
      {/* Card 1 */}
      <div className="card-teorico">
        <img
          className="card-img-teorico"
          src={ClaseOnline}
          alt="Imagen de ejemplo 1"
        />
        <div className="card-content-teorico">
          <h2 className="card-title-teorico">TEÓRICO EN DIRECTO</h2>
          <ul className="card-list-teorico">
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Clases Presenciales y Online
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Mañana y Tarde
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Facilidad de Acceso
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Desde Móvil, Tablet y Ordenador
            </li>
          </ul>
        </div>
      </div>

      {/* Card 2 */}
      <div className="card-teorico">
        <img
          className="card-img-teorico"
          src={Profesor}
          alt="Imagen de ejemplo 2"
        />
        <div className="card-content-teorico">
          <h2 className="card-title-teorico">SEGUIMIENTO POR PROFESOR</h2>
          <ul className="card-list-teorico">
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Evaluación Personalizada
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Resolución de Dudas
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Apoyo Formativo
            </li>
          </ul>
        </div>
      </div>

      {/* Card 3 */}
      <div className="card-teorico">
        <img
          className="card-img-teorico"
          src={Test}
          alt="Imagen de ejemplo 3"
        />
        <div className="card-content-teorico">
          <h2 className="card-title-teorico">REALIZAR TEST</h2>
          <ul className="card-list-teorico">
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Plataforma de Test Online
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Disponibilidad 24 horas al día
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Desde Cualquier Dispositivo
            </li>
            <li className="card-list-item-teorico">
              <i className="fas fa-check check-icon-teorico"></i>
              Material Didáctico
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardTeorico;
