import React from 'react';
import '../estilos/CardVuelveConducir.css';
import Reciclaje from "../imagenes/reciclaje.png";
import MiedoConducir from "../imagenes/miedoconducir.jpg";

const CardTeorico = () => {
  return (
    <div className="cards-container-vuelve-conducir">
      {/* Card 1 */}
      <div className="card-vuelve-conducir">
        <img
          className="card-img-vuelve-conducir"
          src={Reciclaje}
          alt="Imagen de ejemplo 1"
        />
        <div className="card-content-vuelve-conducir">
          <h2 className="card-title-vuelve-conducir">CLASES DE RECICLAJE</h2>
          <ul className="card-list-vuelve-conducir">
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Renueva Conocimientos
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Retoma la Conducción
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Ponte al Día
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Aprende Tendencias Actuales
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Perfecciona en Coche Automático
            </li>
          </ul>
        </div>
      </div>

      {/* Card 2 */}
      <div className="card-vuelve-conducir">
        <img
          className="card-img-vuelve-conducir"
          src={MiedoConducir}
          alt="Imagen de ejemplo 2"
        />
        <div className="card-content-vuelve-conducir">
          <h2 className="card-title-vuelve-conducir">MIEDO A CONDUCIR</h2>
          <ul className="card-list-vuelve-conducir">
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Terapia Personalizada
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Control de Fobias
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Recupera Seguridad y Confianza
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Manejo Emocional
            </li>
            <li className="card-list-item-vuelve-conducir">
              <i className="fas fa-check check-icon-vuelve-conducir"></i>
              Técnicas de Autocontrol
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardTeorico;
