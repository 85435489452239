import React from 'react';
import '../estilos/Localizacion.css';
import FachadaCobisa from '../imagenes/fachadaCobisa.png';
import FachadaPolan from '../imagenes/fachadaPolan.png';

const Localizacion = () => {
  return (
    <>
      <div className="container">
        <div className="image-container">
          <img className="image large-image" src={FachadaCobisa} alt="Fachada Cobisa" />
        </div>
        <div className="content">
          <div className="title">COBISA</div>
          <div className="description">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam suscipit ultricies lectus, a pulvinar enim
            dictum non. Nulla tincidunt odio ac odio euismod, quis dictum enim dignissim. Vivamus gravida tortor a ipsum
            dignissim, ac vulputate ex eleifend. Integer ac cursus nisi. Sed eu turpis at nulla tristique tincidunt. */}
            Avenida Castilla-La Mancha, Número 8, Local 6
          </div>
          <div className="map-container">
            <div className="map-iframe">
              <iframe
                id='mapa_cobisa'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475.79789948312816!2d-4.024020929614382!3d39.80539269161443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6a0c5e966313ff%3A0xb81091d900f202ba!2sAutoescuela%20Cobisa!5e0!3m2!1ses!2ses!4v1690994885045!5m2!1ses!2ses"
                title="Mapa Cobisa"
                loading="lazy"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="content">
          <div className="title">POLÁN</div>
          <div className="description">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam suscipit ultricies lectus, a pulvinar enim
            dictum non. Nulla tincidunt odio ac odio euismod, quis dictum enim dignissim. Vivamus gravida tortor a ipsum
            dignissim, ac vulputate ex eleifend. Integer ac cursus nisi. Sed eu turpis at nulla tristique tincidunt. */}
            Avenida San Sebastián, Número 13
          </div>
          <div className="map-container">
            <div className="map-iframe">
              <iframe
                id='mapa_polan'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d383.23021106729476!2d-4.168054342542435!3d39.78810802477334!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6a71cf0e10ee9f%3A0x37868e4bb60e0aa!2sAutoescuela%20Cobisa!5e0!3m2!1ses!2ses!4v1691276193237!5m2!1ses!2ses"
                title="Mapa Polán"
                loading="lazy"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </div>
        <div className="image-container">
          <img className="image large-image" src={FachadaPolan} alt="Fachada Polán" />
        </div>
      </div>
    </>
  );
};

export default Localizacion;
