import React from "react";
import "../estilos/CardPractico.css";

function Practico() {
  const cardContent1 = (
    <div className="main-container-practico">
      <div className="top-circle-practico"></div>
      <img src={require("../imagenes/cochemanual.png")} alt="Coche" className="user-practico" />
      <h2 className="tituloManual-practico"> Clases con coche MANUAL </h2>
      <p className="description-practico">
        Aprende las técnicas de conducción más eficientes durante nuestras clases prácticas de conducción por Toledo. Controla a la perfección tu coche con marchas.
      </p>
      <ul className="ventajas-practico">
        <li>DIVERSIÓN</li>
        <li>CONTROL</li>
        <li>TRADICIONAL</li>
      </ul>
      <div className="buttons-practico">
        {/* <button className="btn-info-practico"> Más Información </button> */}
      </div>
    </div>
  );

  const cardContent2 = (
    <div className="main-container-practico">
        <div className="ribbon ribbon-top-right">
          <span>NOVEDAD</span>
        </div>
      <div className="circuloAutomatico-practico"></div>
      <img src={require("../imagenes/cocheautomatico.png")} alt="Coche" className="cocheautomatico-practico" />
      <h2 className="tituloAutomatico-practico"> Clases con coche AUTOMÁTICO</h2>
      <p className="description-practico">
        Aprende a conducir de forma más fácil, olvídate del pedal de embrague y la palanca de cambios, con el cambio automático todo es más sencillo.
      </p>
      <ul className="ventajas-practico">
        <li>FACILIDAD</li>
        <li>COMODIDAD</li>
        <li>FUTURO</li>
      </ul>
      <div className="buttons-practico">
        {/* <button className="btn-info"> Más Información </button> */}
      </div>
    </div>
  );

  return (
    <div className="container-grander-practico">
      <div className="card-container-practico">
        <div className="main-container-practico">{cardContent1}</div>
        <div className="main-container-practico">{cardContent2}</div>
      </div>
    </div>
  );
}

export default Practico;

