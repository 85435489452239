import React from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import '../estilos/General.css'
import PoliticaPrivacidadComponente from '../componentes/PoliticaPrivacidad';
import NavbarClasesOnline from '../componentes/NavbarClasesOnline/NavbarClasesOnline';

const PoliticaPrivacidad = () => {
  return (
    <div className="wrapper">
      <NavbarClasesOnline />
      <PoliticaPrivacidadComponente />
      <Footer />
    </div>
  );
}

export default PoliticaPrivacidad;