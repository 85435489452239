import React from 'react';
import Header from '../componentes/Header';
import Footer from '../componentes/Footer';
import TituloEncabezado from '../componentes/TituloEncabezado';
import '../estilos/General.css'
import UnirseClasesOnline from '../componentes/UnirseClasesOnline/UnirseClasesOnline';
import NavbarClasesOnline from '../componentes/NavbarClasesOnline/NavbarClasesOnline';

const DondeEstamos = () => {
  return (
    <div className="wrapper">
      <NavbarClasesOnline />
        <UnirseClasesOnline />
      <Footer />
    </div>
  );
}

export default DondeEstamos;